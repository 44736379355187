import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { addLoad, removeLoadById, addLoads } from './Actions';
import { showConfirm } from '../../../../src/confirm/Actions';
import { showNotice } from '../../../../src/notice/Actions';
import { showMessage } from '../../../../src/message/Actions';
import DataField from '../../../../src/dataField/DataField.js';
import { selectTruck } from '../../../../src/truckSelect/Actions';
import { fetch, floatValue, Socket, stateValueParser } from '../utils.js';
import { MapView, Input } from './Components';
import ChangeLoadView from './Components';
import './Load.css';


const paddedNumber = number => number <= 99 ? ('0' + number).slice(-2) : number;

const FiveLastLoads = props => {
  if (props.loads.size === 0) return null;

  if (props.loading) return <div className='loader'></div>;

  return (
    <div>
      <h4>5 viimeisintä kuormaa</h4>
      {props.loads.splice(0, props.loads.size - 5).map((load) => {
        const date = new Date(load.get('date'));
        const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes());
        const header = load.get('truck') +
          ' | Massa: ' + Math.round(load.get('total_mass') * 1000) / 1000 +
          ' | Laatu: ' + (load.get('quality') || '-') +
          ' | Huomiot: ' + (load.get('attentions') || '-') +
          ' | Luotu: ' + time +
          ' | Käytetty: ' + (load.get('used') ? 'Kyllä' : 'Ei');

        const data = {}

        return (
          <div className='datafield' key={load.get('id')}>
            <DataField header={header} data={data}>
              <div className='row center'>
                <div className='column'>
                  <button onClick={props.changeLoad.bind(this, load)}>
                    Muokkaa
                  </button>
                </div>
                <div className='column'>
                  <button className='button-outline' onClick={props.removeLoad.bind(null, load.get('id'))}>
                    Poista
                  </button>
                </div>
              </div>
            </DataField>
          </div>
        );
      })
      }
    </div>
  );
};


export const LoadNew = (properties) => {
  const props = properties;

  let watchID = null;
  let socket = useRef(null);
  let removingLoad;

  const [truck, setTruck] = useState(null);
  const [mass, setMass] = useState(0);
  const [quality, setQuality] = useState('');
  const [attentions, setAttentions] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [accuracy, setAccuracy] = useState(null);
  const [loadingLoads, setLoadingLoads] = useState(false);
  const [location_road_part, setLocation_road_part] = useState(null);
  const [location_road_pole, setLocation_road_pole] = useState(null);
  const [autoGPS, setAutoGPS] = useState(null);
  const [road_pole, setRoad_pole] = useState(null);
  const [road_part, setRoad_part] = useState(null);
  const [locationErrorState, setLocationErrorState] = useState();
  const [networkError, setNetworkError] = useState();
  const [changingLoad, setChangingLoad] = useState();

  useEffect(() => {
    getLoads();

    if (typeof (Storage) !== 'undefined') {
      setMass(floatValue(localStorage.mass, 0));
      setQuality(localStorage.quality);
      setAttentions(localStorage.attentions);
    }

    if (socket.current == null && typeof (WebSocket) !== 'undefined') {
      socket.current = Socket('/data/');
    }

    if (!navigator.geolocation) {
      props.showMessage('Virhe',
        'Paikan haku ei ole tuettu tällä selaimella eli et voi saada automaattista sijaintia', 'Error');
      return;
    }

    watchID = navigator.geolocation.watchPosition(setLocation, locationError,
      { enableHighAccuracy: true });
    return () => {
      navigator.geolocation.clearWatch(watchID);
      if (socket.current != null) socket.current.close();
    };
  }, [])

  useEffect(() => {
    if (props.selectedTruck != null) {
      setTruck(props.selectedTruck.get('register_number'));
      setMass(props.selectedTruck.get('default_mass'));
    }
  }, [props.selectedTruck])

  useEffect(() => {
    if (props.selectedContract === null) {
      props.addLoads([]);
      return;
    }
  }, [props.selectedContract])

  useEffect(() => {
    if (props.selectedConstructionSite == null) {
      return;
    }
    
    getLoads(props.selectedConstructionSite.get('id'));
    socket.current.onmessage = function (e) {
      const data = JSON.parse(e.data);
      if (data['action'] === 'create') {
        if (data['type'].includes('Mass') && props.selectedConstructionSite &&
          data['model']['construction_site']['id'] === props.selectedConstructionSite.get('id')) {
          props.addMass(data['model']);
        }
      }
      else if (data['action'] === 'update') {
        if (data['type'].includes('Mass') && props.selectedConstructionSite &&
          data['model']['construction_site']['id'] === props.selectedConstructionSite.get('id')) {
          props.changeMass(data['model']);
        }
      }
      else if (data['action'] === 'delete') {
        if (data['type'].includes('Mass') && props.selectedConstructionSite &&
          data['model']['construction_site']['id'] === props.selectedConstructionSite.get('id')) {
          props.removeMassById(data['model']['id']);
        }
      }
    }.bind(this);
  }, [props.selectedConstructionSite])

  const changeState = (propertyName, type, defaultValue, event) => {
    const value = stateValueParser(event, type, defaultValue);

    if (value === undefined) {
      return;
    }

    if (propertyName === 'mass') {
      setMass(value);
    }
    if (propertyName === 'quality') {
      setQuality(value);
    }
    if (propertyName === 'attentions') {
      setAttentions(value);
    }

    if (typeof (Storage) !== 'undefined') {
      localStorage[propertyName] = value;
    }
  }

  // Function to read a property from the state const getStateProperty = (propertyName) => { return state[propertyName]; };

  const setLocation = (position) => {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
    setAccuracy(Math.ceil(position.coords.accuracy));
  }

  const useLocation = () => {
    if (location_road_part == null || autoGPS != null) return;
    setRoad_part(location_road_part);
    setRoad_pole(location_road_pole);
  }

  useEffect(() => {
    localStorage.road_part = road_part;
    localStorage.road_pole = road_pole;
  }, [road_part, road_pole])

  const locationError = (err) => {
    setLocationErrorState(true);
  }

  const onSubmit = () => {
    if (truck === '' && props.truck == null) {
      props.showNotice('Rekkaa ei ole valittu', 'Warning');
      return;
    }

    if (mass === 0) {
      props.showNotice('Massa määrää ei ole annettu', 'Warning');
      return;
    }

    let date = new Date();

    date.setHours(date.getHours());

    const load = {
      constructionSiteId: props.selectedConstructionSite.get('id'),
      truck: truck,
      total_mass: parseFloat(mass),
      quality: quality || '',
      attentions: attentions || '',
      date: date.toISOString().replace('Z', '')
    };

    fetch('/coatingplantloads/', 'POST', load).then(data => {
      props.showNotice('Kuorman lisäys onnistui', 'Ok');
      props.selectTruck(null);

      setMass('');
      setQuality('');
      setAttentions('');

      localStorage.removeItem("mass");
      localStorage.removeItem("quality");
      localStorage.removeItem("attentions");

      props.addLoad(data);
    }).catch(error => {
      if (error.message === '400') {
        props.showMessage('Virhe', 'Massan lisäys epäonnistui virheellisen datan vuoksi', 'Error');
      }
      else {
        props.showMessage('Virhe', 'Palvelin virhe', 'Error');
      }
    });
  }

  const getLoads = (constructionSite) => {
    setLoadingLoads(true);

    let url = '/coatingplantloads';

    if (constructionSite != null) {
      url += '/site/' + constructionSite;
    }

    fetch(url).then(data => {
      setNetworkError(false);
      props.addLoads(data);
    }).catch(error => {
      setNetworkError(true);
    }).then(() => {
      setLoadingLoads(false);
    });
  }

  const goChangeLoad = (loadId) => {
    setChangingLoad(loadId);
  }

  const clearChangeLoad = () => {
    setChangingLoad(null);
  }

  const removeLoad = () => {
    fetch('/coatingplantloads/' + removingLoad + '/', 'DELETE').then(data => {
      props.showNotice('Kuorma poistettu', 'Ok')
      props.removeLoadById(removingLoad);
      removingLoad = null;
    }).catch(error => {
      props.showMessage('Virhe', 'Kuorman poisto epäonnistui', 'Error');
      removingLoad = null;
    });
  }

  const confirmRemoveLoad = (loadId) => {
    removingLoad = loadId;
    props.showConfirm('Poistetaanko kuorma?', removeLoad);
  }

  const resetTruck = () => {
    props.selectTruck(null);
    localStorage.truck = null;
    setTruck(null);
  }

  return (
    <div className='container'>
      <h1>Kuorman lisäys</h1>
      <Input changeState={changeState} mass={mass}
        quality={quality} attentions={attentions}
        truck={truck} resetTruck={resetTruck} store={props.store} />
      <button onClick={onSubmit}>Lisää kuorma</button>
      <FiveLastLoads loads={props.loads}
        loading={loadingLoads}
        removeLoad={confirmRemoveLoad}
        changeLoad={goChangeLoad} />
      <MapView yourLatitude={latitude} yourLongitude={longitude} />
      <ChangeLoadView load={changingLoad} clear={clearChangeLoad} store={props.store} />
    </div>
  );
}

export default connect(state => ({
  loads: state.load.get('loads'),
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  selectedTruck: state.truckSelect.get('selectedTruck'),
}), {
  addLoad, removeLoadById, addLoads, showNotice, showConfirm,
  showMessage, selectTruck
})(LoadNew);
